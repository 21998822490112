import React, { useMemo } from 'react'
import s from './Status.module.scss'
import useLocale from '@/hooks/useLocale'
import cn from 'classnames'
import SuccessIcon from '@/icons/form_success.svg'
import ErrorIcon from '@/icons/form_error.svg'
import Twitter from '@/icons/footer/twitter.svg'
import Linkedin from '@/icons/footer/Linkedin.svg'
import Facebook from '@/icons/footer/facebook.svg'
import Link from 'next/link'
import { X } from '@/icons'

type Iprops = {
  isH5?: boolean
  isOpen?: boolean
  status: 'success' | 'fail' | ''
  onClose?: () => void
}

const Status = (props: Iprops) => {
  const { status, onClose, isH5 } = props
  const { t } = useLocale()
  const errorText = useMemo(() => {
    const a = t("Please try again later or contact us at info@chainup.com for assistance").split('info@chainup.com')
    return a
  }, [t])
  const iconList = useMemo(() => {
    return [
      {
        link: 'https://www.linkedin.com/company/ChainUP-Technology/',
        icon: <Linkedin />
      },
      {
        link: 'https://www.facebook.com/ChainUPTechnology/',
        icon: <Facebook />
      },
      {
        link: 'https://twitter.com/ChainUPOfficial',
        icon: <Twitter />
      }
    ]
  }, [])
  if (status !== 'fail' && status !== 'success') {
    return null
  }

  return (
    <div className={cn(s.wrapper, { [s.wrapperOpen]: props.isOpen })}>
      {onClose && (
        <X
          className={s.close}
          onClick={() => {
            onClose && onClose()
          }}
        />
      )}

      <div className={s.content}>
        {status !== 'fail' ? <SuccessIcon className={cn(s.icon, s.success_icon)} /> : <ErrorIcon className={cn(s.icon, s.error_icon)} />}
        <div className={cn(s.tip)}>{status !== 'fail' ? t('Thank you for your interest!') : t('Something went wrong')}</div>
        <div className={cn(s.decs)}>
          {status !== 'fail'
            ? t('Our experts will connect with you within 2 business days. For more information, you can also follow us on social media')
            : <>{errorText[0]}<a href='mailto:info@chainup.com'>info@chainup.com</a>{errorText[1]}</>}
        </div>
        {!isH5 && (
          <div
            className={s.back_form}
            onClick={() => {
              onClose && onClose()
            }}
          >
            {t('Submit another form')}
          </div>
        )}
        {status == 'success' && (
          <div className={s.link_warpper}>
            {iconList.map((item, index) => (
              <Link key={index} href={item.link}>
                <a target="_blank">{item.icon}</a>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Status
