import React, { useState, useRef, useMemo, useCallback, useImperativeHandle, forwardRef } from 'react'
import styles from './Form.module.scss'
import useLocale from '@/hooks/useLocale'
import classnames from 'classnames'
import Input from '@/components/InputNew'
import Textarea from '@/components/Textarea'
import CountryInput from '@/components/CountryInputNew'
import { useRouter } from 'next/router'
import Schema, { Rules } from 'async-validator'
import request from '@/config/request'
import { Tip } from '@/icons'
import emailFormat from '@/utils/emailFormat'
import { Toast } from '@/components/Toast'

interface dataProps {
  firstName: string
  lastName: string
  company: string
  title: string
  email: string
  countryCode: string
  phone: string
  content: string
}

type Iprops = {
  onSuccess?: () => void
  onFail?: () => void
}

const initValue: dataProps = {
  firstName: '',
  lastName: '',
  company: '',
  title: '',
  email: '',
  countryCode: '',
  phone: '',
  content: ''
}
const ContactBox = (props: Iprops, ref: React.Ref<unknown> | undefined) => {
  const { onSuccess = null, onFail = null } = props
  const { t } = useLocale()
  const { locale } = useRouter()
  const submitLoading = useRef<boolean>(false)
  const [errors, setErrors] = useState<any>({})
  const [errorCode, setErrorCode] = useState<string>('')

  const [formData, changeData] = useState<dataProps>(initValue)
  const firstSubmitRef = useRef<boolean>(true)
  const rules: Rules = useMemo(() => {
    return {
      firstName: [{ required: true, message: t('First Name is required') }],
      lastName: [{ required: true, message: t('Last Name is required') }],
      title: [{ required: true, message: t('Job Title is required') }],
      email: [
        { required: true, message: t('E-mail is required') },
        { type: 'email', message: t('E-mail is invalid') },
        {
          message: t('Please fill-in your work email address'),
          validator: (_, email) => {
            const matchResult = email && email.split('@')?.[1]
            if (matchResult) {
              if (emailFormat.indexOf(matchResult) == -1) {
                return true
              } else {
                return false
              }
            } else {
              return false
            }
          }
        }
      ],
      company: [{ required: true, message: t('Company Name is required') }],
      phone: [{ required: true, message: t('Phone is required') }],
      countryCode: [{ required: true, message: t('Area code is required') }]
    }
  }, [t])
  const resetData = useCallback(() => {
    changeData(initValue)
    setErrors({})
    setErrorCode('')
  }, [])

  const validator = useMemo(() => {
    return new Schema(rules)
  }, [rules])
  const changeVal = useCallback(
    (key: string, value: string | number) => {
      firstSubmitRef.current = true
      changeData({
        ...formData,
        [key]: value
      })
    },
    [formData]
  )
  const checkVal = useCallback(
    (key: string, value: string | number) => {
      validator.validate({ [key]: value }, (err, fields) => {
        if (err && fields[key]) {
          setErrors({ ...errors, [key]: fields[key] })
        } else {
          setErrors({ ...errors, [key]: false })
        }
      })
    },
    [errors, validator]
  )

  const getError = useCallback(
    (field: string) => {
      const item = errors[field]
      if (item) {
        return item[0]?.message
      } else {
        return ''
      }
    },
    [errors]
  )

  const handleGee = async () => {
    const targetUrl = typeof location === 'undefined' ? '' : location.href
    const cookieData = localStorage.getItem('from') || targetUrl
    try {
      submitLoading.current = true
      const res = await request('/system/clue/v4', 'post', {}, { ...formData, cookie: cookieData.slice(0, 225), currentPage: targetUrl.slice(0, 225) })
      if (res.code !== 200) {
        submitLoading.current = false
        onFail && onFail()
        throw new Error()
      }
      firstSubmitRef.current = false
      onSuccess && onSuccess()
        ; (window as any).gtag?.('event', 'form-lead', {
          name: formData.firstName + formData.lastName,
          emailGA: formData.email
        })
        ; (window as any).fbq?.('track', 'Lead')
        ; (window as any).lintrk?.('track', { conversion_id: 11628012 })
    } catch (error) {
      submitLoading.current = false
    } finally {
      submitLoading.current = false
    }
  }

  const handleSubmit = async () => {
    if (firstSubmitRef.current) {
      try {
        if (submitLoading.current) return
        submitLoading.current = true
        await validator.validate({ ...formData })
        handleGee()
      } catch (ex: any) {
        submitLoading.current = false
        setErrorCode(formData.countryCode ? '' : t('Area code is required'))
        setErrors(ex.fields)
      } finally {
        submitLoading.current = false
      }
    } else {
      submitLoading.current = false
      Toast.success(t('You have successfully submitted the relevant information, if you fill in the error can be corrected!'))
    }
  }

  useImperativeHandle(ref, () => ({
    handleSubmit,
    resetData
  }))
  return (
    <>
      {/* <div style={{ height: '100%', overflow: 'auto' }} data-lenis-prevent> */}
      <div className={styles.inputNameWarpper}>
        <Input
          required
          className={classnames(styles.inputWarpper, styles.inputWarpper1)}
          isError={errors.firstName}
          errMsg={getError('firstName')}
          value={formData.firstName}
          onChange={(val) => {
            changeVal('firstName', val)
          }}
          onBlur={(val) => {
            checkVal('firstName', val)
          }}
          lable={t('First Name')}
        />
        {/*名*/}
        <Input
          required
          className={classnames(styles.inputWarpper, styles.inputWarpper1)}
          isError={errors.lastName}
          errMsg={getError('lastName')}
          value={formData.lastName}
          onChange={(val) => {
            changeVal('lastName', val)
          }}
          onBlur={(val) => {
            checkVal('lastName', val)
          }}
          lable={t('Last Name')}
        />
      </div>
      {/*Email*/}
      <Input
        required
        errorStatic
        className={classnames(styles.inputWarpper, styles.inputWarpper_email)}
        isError={errors.email}
        placeholder={t('Please fill-in a work email address')}
        errMsg={getError('email')}
        onBlur={(val) => {
          checkVal('email', val)
        }}
        value={formData.email}
        onChange={(val) => {
          changeVal('email', val)
        }}
        lable={t('Corporate Email Address')}
      />
      <div className={styles.send_email}>
        <Tip className={styles.tip_image} width={5} />
        <div dangerouslySetInnerHTML={{ __html: t("If you are using a personal email address, please send your request to <a href='mailto:info@chainup.com'>info@chainup.com</a>") }}></div>
      </div>
      <CountryInput
        required
        lable={t('Phone')}
        value={formData.phone}
        className={styles.inputWarpper}
        isError={errors.phone}
        errMsg={getError('phone')}
        locale={locale || 'en'}
        errCodeMsg={errorCode}
        onChange={(t: any, v: any) => {
          firstSubmitRef.current = true
          changeData({
            ...formData,
            phone: v,
            countryCode: t
          })
          if (t) {
            setErrorCode('')
          }
          setErrorCode('')
        }}
        onBlur={(val) => {
          checkVal('phone', val)
        }}
      />
      {/*Company Name*/}
      <Input
        required
        className={styles.inputWarpper}
        isError={errors.company}
        errMsg={getError('company')}
        value={formData.company}
        onChange={(val) => {
          changeVal('company', val)
        }}
        onBlur={(val) => {
          checkVal('company', val)
        }}
        lable={t('Company Name')}
      />
      {/*title*/}
      <Input
        required
        className={styles.inputWarpper}
        isError={errors.title}
        errMsg={getError('title')}
        value={formData.title}
        onChange={(val) => {
          changeVal('title', val)
        }}
        onBlur={(val) => {
          checkVal('title', val)
        }}
        lable={t('Job Title')}
      />

      <Textarea
        isResizing={false}
        maxLength={200}
        className={classnames(styles.inputWarpper)}
        isError={errors.content}
        errMsg={getError('content')}
        value={formData.content}
        onChange={(val) => {
          changeVal('content', val)
        }}
        lable={t('Remarks')}
        placeholder={t('Tell us more about your specific needs or interests.')}

      />
      {/* <Button isLoading={submitLoading.current} text={t('Submit')} needAos={false} type="linear" small onClick={handleSubmit} /> */}
    </>
  )
}

export default forwardRef(ContactBox)
