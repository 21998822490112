import { useState, useEffect, useRef, useCallback } from 'react'

// 监听滚动方向及距离（滚动距离加视口高度）;
export const useScrollHooks = () => {
  const [direction, setDirection] = useState<boolean>(false) //false 向上滑动
  const [scrollTop, setscrollTop] = useState<number>(0)
  const valueTop = useRef<number>(0)

  const timeRef = useRef<any>()
  const animTrabslate = useCallback(() => {
    const scrollTop1 = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
    timeRef.current = setTimeout(() => {
      valueTop.current = scrollTop1
    }, 0)
    setscrollTop(scrollTop1)
    setDirection(valueTop.current < scrollTop1)
  }, [])
  let ticking = false
  const getWindowScroll = useCallback(() => {
    if (!ticking) {
      window.requestAnimationFrame(function () {
        animTrabslate && animTrabslate()
        ticking = false
      })
      ticking = true
    }
  }, [animTrabslate])
  useEffect(() => {
    window.addEventListener('scroll', getWindowScroll, {
      passive: true
    })
    return () => {
      clearTimeout(timeRef.current)
      window.removeEventListener('scroll', getWindowScroll)
    }
  }, [getWindowScroll])
  return {
    direction: direction,
    scrollTop
  }
}

// 监听某个元素是否完全出现在视图中;
export const useObserverHook = (
  ele: any,
  callback: any,
  // callback: (arg0?: any) => any,
  watch = [] as any
) => {
  let observer: IntersectionObserver
  useEffect(() => {
    if (ele) {
      observer = new IntersectionObserver((entries) => {
        for (const entry of entries) {
          callback && callback(entry.isIntersecting, entry.boundingClientRect)
        }
      })
      observer.observe(ele) //监听节点
    }
    return () => {
      if (observer && ele) {
        observer.unobserve(ele)
        observer.disconnect()
      }
    }
  }, [ele, ...watch])
}
