import React, { useState, useRef, useCallback, useEffect } from 'react'
import styles from './SolutionsForm.module.scss'
import useLocale from '@/hooks/useLocale'
import classnames from 'classnames'
import Button from '@/components/Button1'
import { ChevronRightBig, X } from '@/icons'
import Form from '@/components/SolutionsForm/Form'
import IconForm from '@/icons/icon-form.svg'
import Status from './Status'
import { useRouter } from 'next/router'
import { getOffset } from '@/utils/scroll'
import { useScrollHooks } from '@/hooks/useScroll'

type Iprops = {
  isH5?: boolean
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  btnText?: string
  btnLink?: string
  hiddenOther?: boolean
  title?: string
}

const ContactBox = (props: Iprops) => {
  const refForm = useRef<any>()
  const refWrapper = useRef<any>()
  const formHeight = useRef<number>(0)

  const { isH5 = false, onClose, isOpen, onOpen, title } = props
  const { t } = useLocale()

  const [status, setStatus] = useState<'success' | 'fail' | ''>('')
  const router = useRouter()
  const { scrollTop } = useScrollHooks()

  useEffect(() => {
    if (!isH5 && refWrapper.current && refWrapper.current.style) {
      window.requestAnimationFrame(function () {
        const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        const footer = getOffset(document.getElementById('footer'), 'top')
        if (formHeight.current == 0) {
          formHeight.current = refWrapper.current.clientHeight
        }
        if (height + scrollTop > footer && refWrapper.current.style) {
          refWrapper.current.style.transform = `translate3d(0px, ${footer - scrollTop - height - formHeight.current / 2}px, 0px)`
        } else if (refWrapper.current.style) {
          refWrapper.current.style.transform = `translate3d(0px, -50%, 0px)`
        }
      })
    }
  }, [scrollTop, isH5])

  const closeForm = useCallback(() => {
    onClose && onClose()
  }, [onClose])

  const handleSubmit = useCallback(() => {
    refForm.current.handleSubmit()
  }, [])
  const onSubmitSuccess = useCallback(() => {
    setStatus('success')
  }, [])

  const onSubmitFail = useCallback(() => {
    setStatus('fail')
  }, [])
  const onCloseStatus = useCallback(() => {
    refForm.current.resetData()
    setStatus('')
  }, [])

  if (isH5) {
    return (
      <>
        {!isOpen ? (
          <div
            className={styles.h5_open_btn}
            onClick={() => {
              onOpen && onOpen()
            }}
          >
            <IconForm className={styles.icon}></IconForm>
          </div>
        ) : (
          <div className={styles.h5Mask}>
            <Status status={status} onClose={onCloseStatus} isH5={isH5}></Status>
            <div className={styles.h5Content}>
              <div className={classnames(styles.title, 'nLhText1')}>
                <span className="colorMain">{title || t('Talk to our experts')} </span>
                <X className={styles.close} onClick={closeForm} />
              </div>
              <div className={styles.form_content} data-lenis-prevent>
                <Form ref={refForm} onSuccess={onSubmitSuccess} onFail={onSubmitFail}></Form>
              </div>
              <div className={styles.btn_warpper}>
                <Button text={t('Submit')} needAos={false} type="linear" size="small" onClick={handleSubmit} className={styles.button} arrow={false} />
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <div className={classnames(styles.wrapper, { [styles.wrapperOpen]: isOpen, [styles.wrapper_ar]: router.locale == 'ar' })} ref={refWrapper} data-lenis-prevent>
      <Status isOpen={isOpen} status={status} onClose={onCloseStatus} isH5={isH5}></Status>
      <div className={classnames(styles.title, 'nLhText1')}>
        {isOpen && <ChevronRightBig className={styles.open_icon} onClick={closeForm} />}
        <div className={classnames(styles.open_text, 'colorMain')}>{title || t('Talk to our experts')} </div>
      </div>
      <Form ref={refForm} onSuccess={onSubmitSuccess} onFail={onSubmitFail}></Form>
      <Button text={t('Submit')} needAos={false} type="linear" size="small" onClick={handleSubmit} />
    </div>
  )
}

export default ContactBox
