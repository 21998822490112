import React, { useEffect } from 'react'
import styles from './Textarea.module.scss'
import classnames from 'classnames'

interface IProps {
  value: string
  onChange?: (value: string) => void
  onKeyDown?: (value: any) => void
  onBlur?: (value: any) => void
  lable?: string
  className?: string
  isError?: boolean
  errMsg?: string
  dataAos?: boolean
  required?: boolean
  placeholder?: string
  disabled?: boolean
  maxLength?: number
  isResizing?: boolean //是否可以拖动改变大小
}

const Textarea = (props: IProps) => {
  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props?.onChange && props?.onChange(e.target.value)
  }
  useEffect(() => { }, [])

  return (
    <div className={classnames(styles.inputContainer, props.className, { [styles.inputContainerDisabled]: props.disabled })} data-aos={props?.dataAos && 'fade-up'}>
      {props.lable && (
        <div className={styles.lable}>
          {props.required && <span className={styles.required}>*&nbsp;</span>}
          {props.lable}
        </div>
      )}
      {/* <div className={classnames(styles.inputContent, { [styles.inputContentError]: props.isError })}> */}
      <div className={styles.box}>
        <textarea
          data-lenis-prevent
          maxLength={props.maxLength}
          disabled={props?.disabled}
          className={classnames(styles.inputContent, { [styles.inputContentError]: props.isError }, { [styles.inputContentResizing]: props.isResizing })}
          placeholder={props.placeholder}
          value={props.value}
          onChange={handleInput}
        />
        {props.maxLength && (
          <div className={styles.lengthCounter}>
            <span>{props.value.length}</span>/{props.maxLength}
          </div>
        )}
      </div>

      {/* </div> */}
      <div className={classnames(styles.error)}>{props.errMsg}</div>
    </div>
  )
}

export default Textarea
